import add from 'lodash/add'
import round from 'lodash/round'

function toNumber (value) {
  return value ? Number(value) : 0
}

export default {
  methods: {
    getSummaries ({ data }) {
      this.$nextTick(() => {
        this.$refs.dataTable.tableDoLayout()
      })
      if (!data.length) return []
      const result = data.reduce((amount, row) => {
        this.columnKeys.forEach((name, index) => {
          const pushIndex = index + 1
          if (!amount[pushIndex]) amount[pushIndex] = 0
          amount[pushIndex] = round(add(amount[pushIndex], toNumber(row[name])), 2)
        })
        return amount
      }, ['合计'])
      const lastIndex = result.length - 1
      result[lastIndex] = result[lastIndex].toFixed(2) + '%'
      return result
    }
  }
}

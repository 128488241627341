<template lang="pug">
.wf-table.full-box
  DataTable(
    v-if="showTable"
    ref="dataTable"
    serverSide
    resource="/orgStat/list"
    :defaultPageSize="9999"
    :needFilterMargin="true"
    :hasPage="false"
    :defaultFilterBy="defaultFilterBy"
    :filterFields="filterFields"
    :columns="columns"
    :settingButtonList="[]"
    show-summary
    :summary-method="getSummaries")
    template(slot="prep")
      h3.table-title 抄收统计表
</template>

<script>
/**
 * 分水厂统计管理
 */
import { mapActions } from 'vuex'
import summary from './mixins/summary'
import { getColumnKeys } from '@/utils/common.js'

export default {
  name: 'OrgStatReport',
  mixins: [summary],
  data () {
    const columns = [
      { name: 'orgName', label: '水厂', width: 120 },
      {
        name: 'totalCurrentReceivable',
        label: '当月应收水费',
        minWidth: 120,
        innerColumns: [
          { name: 'recivedTotalNums', label: '户数' },
          { name: 'recivedTotalQuantity', label: '水量' },
          { name: 'recivedTotalWater', label: '水费' },
          { name: 'recivedTotalResource', label: '水资源费' },
          { name: 'recivedTotalSewage', label: '污水费' },
          { name: 'recivedTotalAmount', label: '金额' }
        ]
      },
      {
        name: 'totalCurrentReceipts',
        label: '当月实收水费',
        minWidth: 100,
        innerColumns: [
          { name: 'receiptsTotalNums', label: '户数' },
          { name: 'receiptsTotalQuantity', label: '水量' },
          { name: 'receiptsTotalWater', label: '水费' },
          { name: 'receiptsTotalResource', label: '水资源费' },
          { name: 'receiptsTotalSewage', label: '污水费' },
          { name: 'receiptsTotalAmount', label: '金额' }
        ]
      },
      {
        name: 'totalCurrentUnrecived',
        label: '当月未收水费',
        minWidth: 100,
        innerColumns: [
          { name: 'unrecivedTotalNums', label: '户数' },
          { name: 'unrecivedTotalQuantity', label: '水量' },
          { name: 'unrecivedTotalWater', label: '水费' },
          { name: 'unrecivedTotalResource', label: '水资源费' },
          { name: 'unrecivedTotalSewage', label: '污水费' },
          { name: 'unrecivedTotalAmount', label: '金额' }
        ]
      },
      {
        name: 'historyBringUp',
        label: '历史转存',
        minWidth: 100,
        innerColumns: [
          { name: 'bringUpWater', label: '水费' },
          { name: 'bringUpResource', label: '水资源费' },
          { name: 'bringUpSewage', label: '污水费' },
          { name: 'bringUpAmount', label: '金额' }
        ]
      },
      {
        name: 'waterFeeRecycleRate',
        label: '当月水费回收率',
        minWidth: 120,
        render: { type: 'padString', suffix: '%' }
      }
    ]
    // 表格列的name数组，舍弃第一列，用于合计行的计算
    const columnKeys = getColumnKeys(columns)
    return {
      showTable: false,
      defaultFilterBy: {
        settlementMonth: this.$day().valueOf(),
        orgId: ''
      },
      // 表格筛选表单配置
      filterFields: [{
        name: 'settlementMonth',
        label: '结算月份',
        form: {
          tag: 'date',
          dateType: 'month',
          clearable: false,
          filterFunc: ({ name, value }) => {
            return [{
              name: name,
              type: 'eq',
              value: this.$day(value).format('YYYY-MM')
            }]
          }
        }
      },
      {
        name: 'orgId',
        label: '水厂',
        form: {
          tag: 'select',
          filterable: true,
          relation: 'waterWorks'
        }
      }
      ],
      // 表格列配置
      columns,
      columnKeys
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    getData () {
      this.$refs.dataTable.reRenderTableData()
    },
    renderRelation () {
      this.getRelations(['waterWorks'])
        .then(res => {
          this.$renderRelationColumns(res, [this.filterFields])
          const orgId = JSON.parse(localStorage.RIGHTS).orgPath.split(',')[2]
          const result = orgId ? Number(orgId) : res.waterWorks.find(i => i.parentId)?.value
          this.$set(this.defaultFilterBy, 'orgId', result)
          this.$nextTick(() => { this.showTable = true })
        })
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>

  <style lang="sass" scoped>
  .wf-table
    display: flex
    flex: 1
    flex-direction: column
    background-color: #eee
    width: 100%
    height: 100%
    .table-title
      text-align: center
      padding-bottom: 8px
    ::v-deep
      .datatablepage-filter
        .dataform-button-item
          min-width: unset !important
  </style>
